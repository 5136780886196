<template>
	<modal class="NoxModalMasterSettings" name="NoxModalMasterSettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addMaster'">Добавление индекса</span>
					<span v-else-if="noxType == 'addAlias'">Добавление алиаса</span>
					<span v-else-if="noxType == 'editMaster'">Редактирование индекса</span>
					<span v-else-if="noxType == 'editAlias'">Редактирование алиаса</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div v-if="['addMaster', 'editMaster'].includes(noxType)">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Токен индекса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxToken" placeholder="токен индекса" autocomplete="off">
									<div v-html="noxAlertToken"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Название индекса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxShortname" placeholder="название индекса" autocomplete="off">
									<div v-html="noxAlertShortname"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Множитель индекса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxMultiplier" placeholder="множитель индекса (пример: 1.0)" autocomplete="off">
									<div v-html="noxAlertMultiplier"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Описание индекса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxDescription" placeholder="описание индекса" autocomplete="off">
									<div v-html="noxAlertDescription"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Статус индекса:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxStatus" placeholder="статус индекса" :options="['publish']"></v-select>
									<div v-html="noxAlertStatus"></div>
								</div>
							</div>
						</div>
						<div v-else-if="['addAlias', 'editAlias'].includes(noxType)">
							<div class="nox_modal_info_row" v-if="noxType == 'addAlias'">
								<div class="nox_modal_info_row_label">Номер индекса:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxMasterId" placeholder="номер индекса" :options="noxMasterIds"></v-select>
									<div v-html="noxAlertMasterId"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Токен алиаса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxToken" placeholder="токен алиаса" autocomplete="off">
									<div v-html="noxAlertToken"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Название алиаса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxShortname" placeholder="название алиаса" autocomplete="off">
									<div v-html="noxAlertShortname"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Описание алиаса:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxDescription" placeholder="описание алиаса" autocomplete="off">
									<div v-html="noxAlertDescription"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="submit" class="nox_button common green" @click="axios" v-if="['addMaster', 'addAlias'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
						<button type="submit" class="nox_button common green" @click="axios" v-else-if="['editMaster', 'editAlias'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertToken: '',
			noxAlertShortname: '',
			noxAlertMultiplier: '',
			noxAlertDescription: '',
			noxAlertStatus: '',
			noxAlertMasterId: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxToken: '',
			noxShortname: '',
			noxMultiplier: '',
			noxDescription: '',
			noxStatus: '',
			noxMasterId: '',
			noxMasterIds: [],
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxToken = '';
				this.noxShortname = '';
				this.noxMultiplier = '';
				this.noxDescription = '';
				this.noxStatus = '';
				this.noxMasterId = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (['editMaster', 'editAlias'].includes(this.noxType)) {

					this.noxData = this.$store.state.noxMasterSettings[this.noxIndex];
					this.noxToken = this.noxData.token;
					this.noxShortname = this.noxData.shortname;
					this.noxMultiplier = this.noxData.multiplier.toFixed(1);
					this.noxDescription = this.noxData.description;
					this.noxStatus = this.noxData.status;
					this.noxMasterId = this.noxData.master_id;
				}

				this.initMasterIds();
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertToken = '';
				this.noxAlertShortname = '';
				this.noxAlertMultiplier = '';
				this.noxAlertDescription = '';
				this.noxAlertStatus = '';
				this.noxAlertMasterId = '';
			},
			initMasterIds: function() {
				this.noxMasterIds = [];
				for (var i in this.$store.state.noxMasterSettings) {
					if (!this.$store.state.noxMasterSettings[i].is_alias) {
						this.noxMasterIds.push(this.$store.state.noxMasterSettings[i].id);
					}
				}
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id индекса.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id индекса.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать id алиаса.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный id алиаса.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать токен индекса.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный токен индекса. Разрешено вводить только латинские буквы и цифры.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать токен алиаса.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный токен алиаса. Разрешено вводить только латинские буквы и цифры.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать название индекса.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верное название индекса. Разрешено вводить только латинские буквы (разрешены все, кроме буквы "e") и цифры.'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать название алиаса.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верное название алиаса. Разрешено вводить только латинские буквы (разрешены все, кроме буквы "e") и цифры.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать множитель индекса.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный множитель индекса.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать множитель алиаса.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верный множитель алиаса.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать описание индекса.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать верное описание индекса.'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать описание алиаса.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать верное описание алиаса.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать статус индекса.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать верный статус индекса.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать статус алиаса.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать верный статус алиаса.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо указать номер индекса.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо указать верный номер индекса.'; }
				else if (i == 27) { this.noxTemp = 'Id индекса не найден в системе.'; }
				else if (i == 28) { this.noxTemp = 'Id алиаса не найден в системе.'; }
				else if (i == 29) { this.noxTemp = 'Номер индекса не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(event) {

				event.preventDefault();

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addMaster', 'editMaster'].includes(_this.noxType)) {
						if (!_this.noxToken) {
							_this.noxAlertToken = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegexENLettersAndNumbers.test(_this.noxToken)) {
							_this.noxAlertToken = _this.getError(6);
						}
						if (!_this.noxShortname) {
							_this.noxAlertShortname = _this.getError(9);
						}
						else if (!_this.$store.state.noxRegexENLettersAndNumbers.test(_this.noxShortname) || _this.noxShortname.toLowerCase().indexOf('e') >= 0) {
							_this.noxAlertShortname = _this.getError(10);
						}
						if (!_this.noxMultiplier) {
							_this.noxAlertMultiplier = _this.getError(13);
						}
						else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxMultiplier)) {
							_this.noxAlertMultiplier = _this.getError(14);
						}
						if (_this.noxDescription && !_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxDescription)) {
							_this.noxAlertDescription = _this.getError(18);
						}
						if (!_this.noxStatus) {
							_this.noxAlertStatus = _this.getError(21);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/bots/masters/only_masters';
							config.data = { id: _this.noxId, token: _this.noxToken, shortname: _this.noxShortname, multiplier: Number(_this.noxMultiplier), description: _this.noxDescription, status: _this.noxStatus };
							config.method = _this.noxType == 'addMaster' ? 'post' : 'patch';
						}
					}
					else if (['addAlias', 'editAlias'].includes(_this.noxType)) {
						if (!_this.noxMasterId) {
							_this.noxAlertMasterId = _this.getError(25);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxMasterId) || _this.noxMasterId < 1) {
							_this.noxAlertMasterId = _this.getError(26);
						}
						if (!_this.noxToken) {
							_this.noxAlertToken = _this.getError(7);
						}
						else if (!_this.$store.state.noxRegexENLettersAndNumbers.test(_this.noxToken)) {
							_this.noxAlertToken = _this.getError(8);
						}
						if (!_this.noxShortname) {
							_this.noxAlertShortname = _this.getError(11);
						}
						else if (!_this.$store.state.noxRegexENLettersAndNumbers.test(_this.noxShortname) || _this.noxShortname.toLowerCase().indexOf('e') >= 0) {
							_this.noxAlertShortname = _this.getError(12);
						}
						if (_this.noxDescription && !_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxDescription)) {
							_this.noxAlertDescription = _this.getError(20);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/bots/masters/only_aliases';
							config.data = { id: _this.noxId, master_id: _this.noxMasterId, token: _this.noxToken, shortname: _this.noxShortname, description: _this.noxDescription };
							config.method = _this.noxType == 'addAlias' ? 'post' : 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addMaster') {
									_this.$store.state.noxMasterSettings.push(data.data);
									_this.initMasterIds();
								}
								else if (_this.noxType == 'addAlias') {
									_this.$store.state.noxMasterSettings.push(data.data.alias_data);
									for (var i in _this.$store.state.noxMasterSettings) {
										if (Number(_this.$store.state.noxMasterSettings[i].id) == Number(_this.noxMasterId)) {
											if (data.data.master_data.aliases !== '') {
												data.data.master_data.aliases = String(data.data.master_data.aliases).replace(/,/g, ', ');
											}
											_this.$store.state.noxMasterSettings.splice(Number(i), 1, data.data.master_data); break;
										}
									}
								}
								else if (['editMaster', 'editAlias'].includes(_this.noxType)) {
									_this.$store.state.noxMasterSettings.splice(_this.noxIndex, 1, data.data);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addMaster', 'editMaster'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'TOKEN_IS_EMPTY') { _this.noxAlertToken = _this.getError(5); }
										else if (data.response.data.error == 'TOKEN_NOT_VALID') { _this.noxAlertToken = _this.getError(6); }
										else if (data.response.data.error == 'SHORTNAME_IS_EMPTY') { _this.noxAlertShortname = _this.getError(9); }
										else if (data.response.data.error == 'SHORTNAME_NOT_VALID') { _this.noxAlertShortname = _this.getError(10); }
										else if (data.response.data.error == 'MULTIPLIER_IS_EMPTY') { _this.noxAlertMultiplier = _this.getError(13); }
										else if (data.response.data.error == 'MULTIPLIER_NOT_VALID') { _this.noxAlertMultiplier = _this.getError(14); }
										else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertDescription = _this.getError(17); }
										else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertDescription = _this.getError(18); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(21); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(22); }
									}
									else if (['addAlias', 'editAlias'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(4); }
										else if (data.response.data.error == 'TOKEN_IS_EMPTY') { _this.noxAlertToken = _this.getError(7); }
										else if (data.response.data.error == 'TOKEN_NOT_VALID') { _this.noxAlertToken = _this.getError(8); }
										else if (data.response.data.error == 'SHORTNAME_IS_EMPTY') { _this.noxAlertShortname = _this.getError(11); }
										else if (data.response.data.error == 'SHORTNAME_NOT_VALID') { _this.noxAlertShortname = _this.getError(12); }
										else if (data.response.data.error == 'MULTIPLIER_IS_EMPTY') { _this.noxAlertMultiplier = _this.getError(15); }
										else if (data.response.data.error == 'MULTIPLIER_NOT_VALID') { _this.noxAlertMultiplier = _this.getError(16); }
										else if (data.response.data.error == 'DESCRIPTION_IS_EMPTY') { _this.noxAlertDescription = _this.getError(19); }
										else if (data.response.data.error == 'DESCRIPTION_NOT_VALID') { _this.noxAlertDescription = _this.getError(20); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(23); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(24); }
										else if (data.response.data.error == 'MASTER_ID_IS_EMPTY') { _this.noxAlertMasterId = _this.getError(25); }
										else if (data.response.data.error == 'MASTER_ID_NOT_VALID') { _this.noxAlertMasterId = _this.getError(26); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'editMaster') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(27); }
									}
									else if (['addAlias', 'editAlias'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(28); }
										else if (data.response.data.error == 'MASTER_ID_NOT_FOUND') { _this.noxAlertMasterId = _this.getError(29); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalMasterSettings');
			}
		}
	}
</script>
